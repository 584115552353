import React, { useState, useEffect } from 'react';
import styles from './ImageGallery.module.css';

function ImageGallery() {
    const images = [
        'newlambrunner.JPG',
    ];

    const [currentImage, setCurrentImage] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setRandomImage();
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const setRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * images.length);
        setCurrentImage(images[randomIndex]);
    };

    const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        const handleClick = () => {
            if (!isMobile) {
                setRandomImage();
            }
        };

        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [isMobile]);

    const backgroundImage = isMobile ? '/wen-lambo-share.png' : `/assets/${currentImage}`;

    return (
        <div 
            className={styles.imageGallery}
            style={{
                backgroundImage: `url(${backgroundImage})`,
            }}
        />
    );
}

export default ImageGallery;