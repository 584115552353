import React from 'react';

function Header() {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <a href="https://x.com/wenlambocto?s=21&t=zzUXfQwF4adSediPcKv95w" target="_blank" rel="noopener noreferrer">
              <img src="/xlogo.png" alt="X" style={{ width: '100px', height: '100px' }} />
            </a>
          </li>
          <li>
            <a href="https://t.me/wenlambotokenonsol" target="_blank" rel="noopener noreferrer">
              <img src="/telegramlogo.png" alt="Telegram" style={{ width: '100px', height: '100px' }} />
            </a>
          </li>
          <li>
            <a href="https://dexscreener.com/solana/64caVVhtRuw23hGwpxKtmzpdh2vA85ke63XFJxvT1ipc" target="_blank" rel="noopener noreferrer">
              <img src="/dexscreener.png" alt="DexScreener" style={{ width: '100px', height: '100px' }} />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;