import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import ImageGallery from './components/ImageGallery';
import FlashingText from './components/FlashingText';
import './App.css';

function App() {
  const [showFlash, setShowFlash] = useState(false);

  useEffect(() => {
    const handleClick = () => {
      setShowFlash(true);
      setTimeout(() => setShowFlash(false), 1000);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="App">
      <ImageGallery />
      <Header />
      
      <h1 className="main-title">$WEN LAMBO</h1>
      <p className="contract-address">
        CA: <strong>9nk2pVMUne7cAKPGxysb21Xd47czC2zHJjpJ6bth4M1c</strong>
      </p>
      {/* {showFlash && <FlashingText />} */}
    </div>
  );
}

export default App;